import React from 'react';
import Footer from '../Footer';
import Header from '../Header';
import Slider from '../Slider/Slider';
import One from '../Slider/One';
import { menus, images, GetHighlightMini_ForFront_Paginate, languagePosition_forArray } from '../Utils';
import Loader from '../Loader';

class Home extends React.Component {
    state = {
        loading: true,
        cards: null,
        labelError: ["Não foi possivel obter os destaques.", "Error getting the highlights"],
        labelInformation: ['Informação','Information']
    }
    componentDidMount() {
        GetHighlightMini_ForFront_Paginate(0).then(result => {
            if (result.status === 200) {
                this.setState({
                    loading: false,
                    cards: result.data.GetHighlightMini_ForFront_PaginateResult,
                });
            } else {
                this.setState({
                    loading: false,
                });
                alert(this.state.labelError[languagePosition_forArray]);
            }
        });
    }

    render() {
        return (
            <>
                <Header hasTitle={false} showSideBar={true} InformationButton={true} />
                <div className="main">
                    {
                        this.state.loading ?
                            <Loader />
                            :
                            <>
                                {this.state.cards != null && this.state.cards.length > 0 ?
                                this.state.cards.length === 1 ? <One cards={this.state.cards} /> : <Slider cards={this.state.cards} homepage={true} />
                                    :
                                     <></>}
                                <div className="menu-container">
                                    <div className="container">
                                        <div className="menu-wrapper">
                                            {menus.map((menu) => (
                                                menu.showOnMenu ?
                                                    <div key={menu.id} className={menu.activeState ? "menu-item-div-active" : "menu-item-div"}
                                                        style={{ background: menu.id === 1 ? "#ffed00" : "#efefef" }}>
                                                        {/*menu.activeState ?*/ menuItemActive(menu)/* : menuItemDesactive(menu)*/}
                                                    </div>
                                                    : ''
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </>
                    }
                </div>
                <Footer />
            </>
        )
    }
}

const menuItemActive = menu => {
    return (
        <a href={menu.url}>
            <div>
                <img src={images('./' + menu.img + '.png')} alt={menu.name[languagePosition_forArray]} />
            </div>
            <span className="menu-name">{menu.name[languagePosition_forArray]}</span>
        </a>
    )
}

/*const menuItemDesactive = menu => {
    return (
        <div>
            <div>
                <img src={images('./' + menu.img + '.png')} alt={menu.name} />
            </div>
            <span className="menu-name">{menu.name}</span>
            <span className="menu-briefly">Brevemente</span>
        </div>
    )
}*/

export default Home;